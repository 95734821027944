<template>
    <div>
        <div class="container">
            <div class="navigationMargin">
                <h2 class="pt-4 pb-4 text-center">Privacy Policy</h2>
                <p>(1) Putnik osobne podatke daje dobrovoljno i Putnik potpisom Ugovora o putovanju u paket aranžmanu
                    daje privolu da se njeni osobni podaci mogu koristiti u svrhu marketinških akcija Organizatora.</p>
                <p>(2) Obavijesti koje dobije o Putniku, njegovoj prtljazi, njegovim kretanjima i imenima njegovih
                    suputnika Organizator smije priopćiti trećim osobama samo s posebnom privolom putnika ili na zahtjev
                    nadležnog tijela javne vlasti te u svrhu realizacije ugovorenih usluga putovanja, ugovaranje
                    osiguranja od rizika otkaza i osiguranja od posljedica nesretnog slučaja i bolesti, osiguranje od
                    rizika oštećenja i gubitka prtljage i dragovoljno zdravstveno osiguranje za vrijeme puta i boravka u
                    inozemstvu.</p>
                <p>(3) Osobni podaci putnika čuvati će se u bazi podataka Organizatora, sukladno odluci Uprave o načinu
                    prikupljanja, obrade i čuvanja osobnih podataka.</p>
                <p>&nbsp;</p>
            </div>
        </div>
    </div>
</template>

<script>

</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.navigationMargin {
    @media screen and (min-width: 768px) {
        margin-left: 80px;
        padding-bottom: 30px;
    }
    padding-bottom: 90px;
}

</style>
